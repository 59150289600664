<template>
  <div class="wrapper">
    <img loading="lazy" alt="icon" id="img-top" src="/images/emprendedores/resources.jpg" />
    <div class="background first">
      <div class="container">
        <h2>{{ $t('emprendedores.recources_for_entrepreneur') }}</h2>
        <div class="lcont">
          <div class="lrow">
            <span>ASPECTOS LEGALES PARA EMPRENDER</span>
            <router-link to="/emprendedores/recursos/aspectos-legales" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
          <div class="lrow">
            <span>PUBLICACIONES E INFORMES</span>
            <router-link to="/emprendedores/recursos/publicaciones" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
          <div class="lrow">
            <span>AYUDAS AL EMPRENDEDOR</span>
            <router-link to="/emprendedores/recursos/ayudas-emprendedor" id="download" >
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
          <div class="lrow">
            <span>ACUERDOS DE COLABORACIÓN</span>
            <router-link to="/emprendedores/recursos/acuerdos-colaboracion" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
          <div class="lrow">
            <span>AYUDAS A LA FINANCIACIÓN</span>
            <router-link to="/emprendedores/recursos/ayudas-financiacion" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
          <div class="lrow">
            <span>PREMIOS PARA EMPRENDEDOR</span>
            <router-link to="/emprendedores/recursos/premios-emprendedor" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
          <div class="lrow">
            <span>SIMULADORES DE PLAN DE EMPRESA</span>
            <router-link to="/emprendedores/recursos/simuladores-empresa" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
          <div class="lrow">
            <span>PROTECCIÓN DE LA INNOVACIÓN</span>
            <a target="_blank" href="/resources/proteccion-innovacion2.pdf" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </a>
          </div>
          <div class="lrow">
            <span>REVISTAS DE EMPRENDEDORES</span>
            <router-link to="/emprendedores/recursos/revistas-emprendedores" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
          <div class="lrow">
            <span>BIBLIOTECA EBOOK</span>
            <router-link to="" id="download">
              Ver
              <font-awesome-icon :icon="['fas', 'eye']" class="icon-pdf"/>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('emprendedores.recources_for_entrepreneur'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.recources_for_entrepreneur') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.recources_for_entrepreneur') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  #img-top {
    width: 100%;
  }

  .wrapper {
    text-align: left;

    .background.first {
      background: #f2f2f2;    
      padding: 50px 0;

      .lcont {
        width: 90%;
        padding: 20px 0 0;

         .lrow {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          border-bottom: 2px solid #dedede;
          margin-bottom: 35px;

          a:not(#download) {
            text-transform: uppercase;
          }

          a#download {
            cursor: pointer;

            .icon-pdf {
              margin-left: 10px;
            }
          }

          a#download:hover {
            text-decoration: underline;

            .icon-pdf {
              opacity: 0.8;
            }
          }

          a,
          span {
            font-size: 1.3rem;
            color: #4c4c4c;
          }

          .icon-pdf {
            color: #009090;
            font-size: 2rem;
            vertical-align: middle;
          }
        }
      }
    }
  }
</style>