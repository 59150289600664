<template>
  <div class="wrapper">
    <img loading="lazy" alt="icon" id="img-top" src="/images/emprendedores/acelerationprojects.jpg" />
    <div class="background first">
      <div class="container">
        <h2>{{ $t('emprendedores.project-acceleration') }}</h2>
        <div class="contentas">
          <h3>LANZA TU PROYECTO CON ÉXITO</h3>
          <p>
            A través del PROGRAMA DE ACELERACIÓN DE PROYECTOS, el CLUB
            ayuda a emprendedores de alto potencial a desarrollar su
            negocio y a emplear, en cada fase de su proceso de
            crecimiento, los recursos adecuados para avanzar de manera
            óptima.
          </p>
        </div>
        <div class="contentas">
          <h3>PREPARA TU PROYECTO PARA HACERLO CRECER</h3>
          <p>
            La creación de proyectos requiere esfuerzo, tiempo, conocimiento
            de técnicas, métodos y herramientas específicas. Con frecuencia
            no existe ninguna experiencia anterior, ni métricas conocidas de
            los modelos de negocio sobre los que se basan. Su puesta en
            marcha es un camino que nadie ha transitado.
          </p>
          <p>
            Este programa tiene carácter de entrenamiento. Desarrolla en los
            emprendedores las habilidades necesarias para poner en marcha
            proyectos, acelerarlos y finalmente optimizarlos para su
            presentación ante inversores.
          </p>
          <p>
            Además de las sesiones programadas, los participantes “saldrán
            del edificio” para testar sus hipótesis y avanzar en el desarrollo
            de su modelo de negocio. Identificando sus elementos de valor y
            construyendo las ventajas competitivas que ofrecerán frente a la
            competencia. También contarán con mentores especializados que les
            ayudarán en aquellos aspectos de mayor relevancia dentro del estado
            de su proyecto.
          </p>
          <p>
            Para garantizar el máximo aprovechamiento por parte de los
            participantes, el programa está reservado a un número reducido de
            proyectos.
          </p>
          <p>
            La superación del proceso de selección por parte de las startups
            facilitará a los emprendedores el acceso al foro de inversión del
            CLUB. Dentro del propio curso, aprenderán los aspectos clave que
            deben tener en cuenta a la hora de negociar con inversores. Y
            prepararán con un profesional especializado su presentación al foro
            de inversión una vez superados los criterios de selección por parte
            del CLUB.
          </p>
        </div>
      </div>
    </div>
    <div class="background second"> 
      <div class="container">
        <div class="contentas">
          <h3>PARTICIPAR EN EL PROGRAMA TE PERMITIRÁ</h3>
          <div class="flexas">
            <b-row>
              <b-col cols="12" class="col-md-3 prog border-right">
                <img loading="lazy" alt="icon" src="/images/emprendedores/icon-participate1.png" />
                <p>
                  Validar tu idea para saber identificar quién es o será su
                  cliente.
                </p>
              </b-col>
              <b-col cols="12" class="col-md-3 prog border-right">
                <img loading="lazy" alt="icon" src="/images/emprendedores/icon-participate2.png" />
                <p>
                  Diseñar una propuesta de valor que responda a sus necesidades
                  y deseos del cliente.
                </p>
              </b-col>
              <b-col cols="12" class="col-md-3 prog border-right">
                <img loading="lazy" alt="icon" src="/images/emprendedores/icon-participate3.png" />
                <p>Establecer un proceso de venta rentable y escalable.</p>
              </b-col>
              <b-col cols="12" class="col-md-3 prog">
                <img loading="lazy" alt="icon" src="/images/emprendedores/icon-participate4.png" />
                <p>
                  Presentar tu proyecto a inversores conociendo las claves de
                  negociación.
                </p>
              </b-col>
            </b-row>
          </div>
          <div>
            <h4>El programa se estructura en:</h4>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Ocho talleres formativos, de cuatro horas cada uno impartidos
                porprofesionales de la innovación, inversión y desarrollo de
                proyectos.
              </span>
            </div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Sesiones de mentoring personalizado con cada uno de los equipos
                emprendedores. Asignadas a partir de las necesidades específicas
                de cada proyecto.
              </span>
            </div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Trabajo “fuera del edificio” por parte de los emprendedores. Con
                la supervisión y corrección en clase por parte del ponente.
                Asegurando la mejora y crecimiento constante del proyecto.
              </span>
            </div>
          </div>
        </div>
        <div class="contentas">
          <h3>TALLERES</h3>
          <p>
            En los talleres, además de exponerse los conceptos y enfoques teóricos
            de los métodos, herramientas y estrategias para la creación y la puesta
            en marcha de los negocios, se expondrán casos y ejemplos reales.
          </p>
          <div>
            <Acordion />
          </div>
        </div>
        <div class="contentas">
          <h3>PREPARACIÓN PARA FORO DE INVERSIÓN</h3>
          <div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                A lo largo del programa, los proyectos participantes se prepararán
                para el acceso a la financiación, elaborarán su propia propuesta y
                aprenderán las claves para la negociación y cierre con éxito de una
                ronda de inversión.
              </span>
            </div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Una vez finalizado el programa, el CLUB facilitará a los proyectos
                seleccionados su participación en el Foro de Inversión del CLUB.
              </span>
            </div>
          </div>
        </div>
        <div class="contentas">
          <h3>ASESORAMIENTO ESPECIALIZADO</h3>
          <div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Las sesiones grupales, personalizadas, de mentoring y el trabajo
                individual de los equipos les facilitarán las herramientas para la
                construcción, desarrollo y lanzamiento de su modelo de negocio y
                la comercialización de sus productos/servicios.
              </span>
            </div>
          </div>
        </div>
        <div class="contentas">
          <h3>SOPORTES</h3>
          <p>
            Los equipos emprendedores dispondrán de herramientas de evaluación y
            preparación de sus proyectos.
          </p>
          <div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Contarán con el apoyo de toda la red de asociados del CLUB en sus
                centros de trabajo y podrán optar a espacios en la Incubadora del
                CLUB Emprendedores en función de disponibilidad.
              </span>
            </div>
          </div>
        </div>
        <div class="contentas">
          <h3>
            BUSCAMOS EMPRENDEDORES QUE QUIERAN HACER CRECER O CONSOLIDAR SU IDEA
            DE NEGOCIO
          </h3>
          <p>
            A través del Programa de Aceleración de proyectos, el CLUB proporciona
            a los emprendedores herramientas para construir un modelo de negocio
            rentable y escalable. Los acompaña en su proceso de validación y les
            muestra las claves para obtener financiación en su proyecto y hacerlo
            crecer.
          </p>
          <p>
            Impartido por profesionales de la aceleración de proyectos, innovación
            y fundadores de distintas startups, el programa ofrece a sus
            participantes seleccionados una oportunidad única para hacer despegar
            su proyecto.
          </p>
          <div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Ocho talleres de cuatro horas cada uno.
              </span>
            </div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Sesiones de mentoring personalizado.
              </span>
            </div>
            <div class="bullet">
              <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
              <span>
                Acompañamiento en el desarrollo de cada uno de los proyectos.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contacts />
    <b-row class="clearfix" style="background: #f3f3f3; height: 50px;"></b-row>
  </div>
</template>

<script>
import Acordion from '../../components/entrepreneurs/Acordion';
import Contacts from '../../components/ContactsForm';

export default {
  metaInfo() {
    return {
      title: this.$t('emprendedores.project-acceleration'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.project-acceleration') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.project-acceleration') + ' - Club de Ejecutivos' },
      ]
    }
  },
  components: {
    Acordion,
    Contacts
  }
}
</script>

<style scoped lang="scss">
  #img-top {
    width: 100%;
  }

  .wrapper {
    text-align: left;

    h2 {
      margin-bottom: 60px;
    }

    h3 {
      margin: 30px 0;
    }

    .contentas {
      margin-bottom: 60px;
    }

    .background.first {
      background: #f2f2f2;    
      padding: 50px 0;
    }

    .second {
      padding-bottom: 10px;

      .contentas {
        .flexas {
          margin-bottom: 30px;
        }

        .border-right {
          border-right: 1px solid #bababa;
          height: 185px;
        }

        .prog {
          text-align: center;
        }

        .bullet {
          margin: 15px 0;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .second {
      .contentas {
        .border-right {
          height: unset !important;
        }
      }
    }
  }
</style>